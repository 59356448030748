import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "presence" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)



  return (
    <AboutWrapper id="about">
      <Container>
        <Flex>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>

          <AboutTextGroup>
            <Subtitle>Work with us</Subtitle>
            <h1>
            Let's build operational resilience together
            </h1>
            <h2>
            <br/>Thanks to our strategic partnerships, we can focus on what we do best: understanding your needs and helping you make better business decisions.
            <br/>With 10+ years experience in the MENA region, we combine our expert planning, innovative approach and continuous improvement to deliver end-to-end solutions.
            </h2>
          </AboutTextGroup>

        </Flex>
      </Container>
    </AboutWrapper>
  )
}

export default About

const AboutWrapper = styled.div`
  padding: 160px 0 80px 0;
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`

const AboutTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 12px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`



const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: left;
`



const ImageWrapper = styled.div`
  justify-self: start;
  padding-left:15%;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
    padding-left:0;
  }
`


const StyledImage = styled(Img)`
  width: 350px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 250px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 150px;
    display: none;
  }
`

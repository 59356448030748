import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import About from "../components/sections/about"
import Product from "../components/sections/product"
import Services from "../components/sections/services"
import Footer from "../components/sections/footer"


const IndexPage = () => (
  <Layout>
    <SEO title="SBIS" />
    <Navigation />
    <Header />
    <About />
    <Services />
    <Product />
    <Footer />
  </Layout>
)

export default IndexPage

import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Services = () => (
  <Section id="services">
    <StyledContainer>
      <Subtitle>Services</Subtitle>
      <SectionTitle>How can we help you?</SectionTitle>
      <ServicesGrid>
        <ServiceItem>
          <ServiceTitle>Research</ServiceTitle>
          <ServiceText>
            Understand your cash supply chain process
          </ServiceText>
        </ServiceItem>
        <ServiceItem>
          <ServiceTitle>Guidance</ServiceTitle>
          <ServiceText>
            Pick the right machinery. Banknote sorting, self-service deposit etc.
          </ServiceText>
        </ServiceItem>
        <ServiceItem>
          <ServiceTitle>Integration</ServiceTitle>
          <ServiceText>
            Connect your equipment to cash management systems
          </ServiceText>
        </ServiceItem>
        <ServiceItem>
          <ServiceTitle>Development</ServiceTitle>
          <ServiceText>
            Build flexible, scalable and reliable analytics software
          </ServiceText>
        </ServiceItem>
      </ServicesGrid>
    </StyledContainer>
  </Section>
)

export default Services

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const ServicesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const ServiceItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ServiceTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const ServiceText = styled.p`
  text-align: center;
`
